<template>
  <div>
    <b-row class="justify-content-md-center">
      <b-col cols="12">
        <b-card
          :title="$t('Manual Analysis.Jobs.title')"
        >
          <b-row
            class="d-flex flex-row"
          >
            <b-col
              cols="12"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :label="$t('Documents.Previous Analysis.perPage.label')"
                label-for="perPageSelect"
                :description="$t('Documents.Previous Analysis.perPage.description')"
              >
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  :options="pageOptions"
                  @change="fetchIfNotFiltering()"
                />
              </b-form-group>
            </b-col>
            <!-- Datepicker Input Begin -->
            <b-col
              cols="6"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Documents.Previous Analysis.fromDate.description')"
                :label="$t('Documents.Previous Analysis.fromDate.label')"
                label-for="from-date-text"
                :invalid-feedback="$t('Documents.Previous Analysis.fromDate.feedback')"
                :state="fromDateState()"
              >
                <b-input-group>
                  <b-form-input
                    id="from-date-text"
                    v-model="fromDateString"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    :state="fromDateState()"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="fromDateString"
                      :initial-date="fromInitialDate"
                      :max="maxDate"
                      button-only
                      right
                      :button-variant="fromDateOutlineVariant()"
                      size="sm"
                      aria-controls="from-date-text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col
              cols="6"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Documents.Previous Analysis.toDate.description')"
                :label="$t('Documents.Previous Analysis.toDate.label')"
                label-for="to-date-string"
                :invalid-feedback="$t('Documents.Previous Analysis.toDate.feedback')"
                :state="toDateState()"
              >
                <b-input-group>
                  <b-form-input
                    id="to-date-text"
                    v-model="toDateString"
                    type="text"
                    placeholder="YYYY-MM-DD"
                    autocomplete="off"
                    :state="toDateState()"
                  />
                  <b-input-group-append>
                    <b-form-datepicker
                      v-model="toDateString"
                      :max="maxDate"
                      button-only
                      right
                      :button-variant="toDateOutlineVariant()"
                      size="sm"
                      aria-controls="to-date-text"
                    />
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- Datepicker Input End -->
            <b-col
              cols="12"
              md="2"
              class="align-items-center justify-content-start"
            >
              <b-form-group
                :description="$t('Documents.Previous Analysis.sort.description')"
                :label="$t('Documents.Previous Analysis.sort.label')"
                label-for="sortBySelect"
              >
                <!-- TODO: Apply translation to this component-->
                <b-input-group>
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-75"
                  >
                    <template v-slot:first>
                      <option value="">
                        -- none --
                      </option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    :disabled="!sortBy"
                    class="w-25"
                  >
                    <option :value="false">
                      Asc
                    </option>
                    <option :value="true">
                      Desc
                    </option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col
              md="4"
              class="align-items-center justify-content-end"
            >
              <b-form-group
                :description="$t('Documents.Previous Analysis.filter.description')"
                :label="$t('Documents.Previous Analysis.filter.label')"
                label-for="filterInput"
              >
                <b-input-group>
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    :placeholder="$t('Documents.Previous Analysis.filter.placeholder')"
                  />
                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      variant="outline-primary"
                      @click="filter = ''"
                    >
                      {{ $t('Documents.Previous Analysis.filter.buttonText') }}
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-table
                striped
                hover
                responsive
                :per-page="perPage"
                :current-page="0"
                :items="items"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >

                <template
                  #cell(status)="data"
                >
                  <b-badge
                    :variant="statuses[1][data.value]"
                  >
                    {{ statuses[0][data.value] }}
                  </b-badge>
                </template>

                <template #cell(analysis_type)="data">
                  {{ analysisTypes[0][data.value] }}
                </template>

                <template #cell(request_date)="data">
                  <!-- human format: (new Date(Date.parse(data.value))).toLocaleDateString("es-CL", { year: 'numeric', month: 'long', day: 'numeric' })  -->
                  {{ (new Date(Date.parse(data.value))).toLocaleString("es-CL") }}
                </template>

                <template #cell(view)="row">
                  <b-button
                    variant="flat-primary"
                    class="btn-icon rounded-circle"
                    @click="viewJob(row.item)"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </template>

              </b-table>
            </b-col>

            <b-col
              cols="12"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
                @change="fetchIfNotFiltering"
              />
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow, BCol, BCard, BTable, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
  BFormDatepicker, // BSpinner,
} from 'bootstrap-vue'

import {
  sub, endOfToday, format, endOfDay, isBefore, startOfToday, parse, isValid, formatRFC3339, // min,
} from 'date-fns'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormDatepicker,
  },
  data() {
    // Date initialization
    const todaysEnd = endOfToday()
    const todaysStart = startOfToday()
    const oneMonthBefore = sub(todaysStart, { months: 1 })

    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'status',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'id', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.id') },
        { key: 'status', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.status'), sortable: true },
        { key: 'email', label: this.$i18n.t('Reports.Requests.table.fieldLabels.email') },
        { key: 'rut', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.rut') },
        { key: 'name', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.name') },
        { key: 'analysis_type', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.analysis_type') },
        { key: 'request_date', label: this.$i18n.t('Documents.Previous Analysis.table.fieldLabels.request_date') },
        { key: 'view', label: this.$i18n.t('Reports.Requests.table.fieldLabels.view') },
      ],
      items: [
        // {
        //   id: 173,
        //   status: 1,
        //   email: 'test@test.cl',
        //   rut: '99.999.999-9',
        //   name: 'LMGMR Ltda.',
        //   analysis_type: 3,
        //   request_date: '2022-02-02T02:47:14Z',
        //   action: true,
        //   actionType: 'eeud',
        // },
        // {
        //   id: 178,
        //   status: 0,
        //   email: 'test@test.cl',
        //   rut: '99.999.999-9',
        //   name: 'LMGMR Ltda.',
        //   analysis_type: 3,
        //   request_date: '2022-02-02T03:47:14Z',
        //   action: true,
        //   actionType: 'eeud',
        // },
        // {
        //   id: 646,
        //   status: 2,
        //   email: 'test@test.cl',
        //   rut: '99.999.999-9',
        //   name: 'LMGMR Ltda.',
        //   analysis_type: 3,
        //   request_date: '2022-02-02T04:47:14Z',
        //   action: true,
        //   actionType: 'eeud',
        // },
      ],
      // TODO: Apply translation to statuses' labels
      statuses: [{
        0: 'Pendiente',
        1: 'Con Observaciones',
        2: 'Listo',
        // 3: 'Sin Modificaciones',
        // 4: 'Sin Conformidad',
        // 5: 'Requiere Ayuda',
        // 6: 'Con actividades',
        // 7: 'Sin actividades',
        // 8: 'Sin Modificaciones',
      },
      {
        0: 'light-warning',
        1: 'light-danger',
        2: 'light-success',
        // 3: 'light-dark',
        // 4: 'warning',
        // 5: 'light-primary',
        // 6: 'light-success',
        // 7: 'dark',
        // 8: 'light-dark',
      }],
      analysisTypes: [{
        0: 'Persona Natural',
        1: 'Formato Ripley',
        2: 'Bancarias Especiales',
        3: 'Bancarias Normales',
        4: 'Operaciones de Credito',
        5: 'Formato MAF',
      }],
      toDateString: format(todaysEnd, 'yyyy-MM-dd'),
      fromDateString: format(oneMonthBefore, 'yyyy-MM-dd'),
      fromInitialDate: oneMonthBefore,
      maxDate: todaysEnd,
    }
  },
  sockets: {
    onJobStatusUpdate(data) {
      console.log(data)
      if (data.error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Toast.titles.withID', { id: data.job_id }),
            icon: 'BellIcon',
            text: this.$i18n.t('Toast.texts.couldntReport'),
            variant: 'danger',
          },
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$i18n.t('Toast.titles.withID', { id: data.job_id }),
            icon: 'BellIcon',
            text: this.$i18n.t('Toast.texts.successReport'),
            variant: 'success',
          },
        })
        this.fetchData()
      }
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    toDateString: 'fetchWhenValid',
    fromDateString: 'fetchWhenValid',
  },
  created() {
    // eslint-disable-next-line
    this.$nextTick(function () {
      this.fetchData()
    })
  },
  methods: {
    // Add page and page_size as parameters
    // Only fetch from backend when not filtering
    // Unless it's by date
    fetchData(nextPage) {
      this.$http.get('/v2/manual/jobs', {
        params: {
          page: nextPage || this.currentPage,
          page_size: this.perPage,
          ...(
            // Include date params only when both are valid
            (this.fromDateState() === null
              && this.toDateState() === null) ? {
                fromDate: formatRFC3339(this.fromDateAsDate()),
                toDate: formatRFC3339(this.toDateAsDate()),
              } : {}
          ),
        },
      })
        .then(response => {
          this.items = response.data.data
          this.totalRows = response.data.pagination.total_size
          // console.log(response.data)
        })
        .catch(error => {
          console.error('There was an error: ', error)
        })
    },
    viewJob(item) {
      // DONE: Redirect to Job
      this.$router.push({ name: 'lawyer-job', params: { id: item.id } })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    fetchIfNotFiltering(nextPage) {
      // console.log('Filter:', this.filter)
      // console.log('Page:', this.currentPage)
      // console.log('Next Page:', nextPage)
      // console.log('Per Page:', this.perPage)
      if (!this.filter) {
        this.fetchData(nextPage)
      }
    },
    // Beginning of date-related functions
    fromDateAsDate() {
      return parse(this.fromDateString, 'yyyy-MM-dd', new Date())
    },
    toDateAsDate() {
      return endOfDay(parse(this.toDateString, 'yyyy-MM-dd', new Date()))
    },
    // Validator for From-Date datepicker
    fromDateState() {
      return isValid(this.fromDateAsDate()) ? null : false
    },
    // Validator for To-Date datepicker
    toDateState() {
      // console.log('toDateState:', this.fromDateAsDate(), this.toDateAsDate())
      // console.log(isBefore(this.fromDateAsDate(), this.toDateAsDate()))
      return isBefore(this.fromDateAsDate(), this.toDateAsDate()) ? null : false
    },
    fromDateOutlineVariant() {
      if (this.fromDateState() === false) {
        return 'outline-danger'
      }
      return 'outline-primary'
    },
    toDateOutlineVariant() {
      if (this.toDateState() === false) {
        return 'outline-danger'
      }
      return 'outline-primary'
    },
    fetchWhenValid() {
      // If both validators pass, fetch from backend
      if (this.fromDateState() === null && this.toDateState() === null) {
        this.fetchData()
      }
    },
    // End of date-related functions
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
